export default {
  "invite-review": "ccT",
  "invite-review-info": "ccz",
  "invite-review-info--invitee": "cca",
  "disclaimer-button": "ccH",
  "info-block": "ccO",
  "ev": "ccm",
  "tooltip": "ccp",
  "description": "ccx"
};
