export default {
  "card": "cJm",
  "focus": "cJp",
  "title-format": "cJx",
  "header": "cJw",
  "headings": "cJN",
  "title": "cJv title-4",
  "main": "cJo title-3",
  "footer": "cJn"
};
