export default {
  "container": "Au",
  "header-wrapper": "YS mb-16",
  "header": "Yc",
  "logo": "Yq mr-16",
  "title": "YZ title-4",
  "description": "YR body-2",
  "conditions": "YQ",
  "label": "Ye caption mb-4",
  "tags": "YB"
};
