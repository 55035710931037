export default {
  "container": "KQ",
  "container-content": "Ke",
  "content": "KB",
  "underline": "KE",
  "inline-content": "Kd",
  "payment-rate": "KI",
  "payment-subtitle": "Kt",
  "grey-text": "KM",
  "earnings-header": "KP",
  "earnings-tooltip": "Kl"
};
