export default {
  "container": "bE",
  "centered": "bd",
  "content": "bI",
  "spinner": "bt",
  "holder-selector": "bM",
  "holder-selector-error": "bP",
  "info-text": "bl",
  "error-text": "bX",
  "lottie-illustration": "bC",
  "info-text-1": "bk",
  "info-text-2": "bJ"
};
