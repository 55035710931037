export default {
  "body-loading": "csg",
  "body-members": "csD",
  "body-details": "csV",
  "body-details-title": "csL",
  "icon": "csT",
  "body-details-header": "csz",
  "body-details-ids-subtitle": "csa",
  "body-details-overview-subtitle": "csH",
  "body-details-overview-content": "csO",
  "body-details-ctas": "csm",
  "body-details-ctas-left": "csp",
  "body-details-ctas-right": "csx",
  "right-edit": "csw"
};
