export default {
  "member-details": "csv",
  "header": "cso",
  "no-email": "csn",
  "header-img": "csi",
  "invited-persona": "csA",
  "revoked-persona": "csY",
  "invited-icon": "csh",
  "revoked-icon": "csf",
  "revised": "csK",
  "invitable-icon": "csG",
  "dropdown": "csr",
  "header-body": "csb",
  "status": "csy",
  "name": "csU title-3",
  "email": "csj caption-bold",
  "actions": "csW body-2",
  "actions-cards": "csu",
  "actions-transactions": "cFS",
  "body": "cFc",
  "expense-permissions-section": "cFq",
  "body-title": "cFZ title-4",
  "body-attr": "cFR",
  "body-label": "cFQ",
  "attr-buttons": "cFe",
  "body-role": "cFB small",
  "details-actions": "cFE",
  "actions-item": "cFd",
  "disclaimer": "cFI",
  "permissions": "cFt",
  "permission": "cFM",
  "permission-label": "cFP",
  "permission-check": "cFl",
  "permission-missing": "cFX",
  "editable-section": "cFC",
  "hris-placeholder": "cFk",
  "imported-from": "cFJ",
  "integration-logo": "cFs",
  "permission-value": "cFF",
  "tooltip": "cFg",
  "check-icon": "cFD"
};
