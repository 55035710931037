export default {
  "cell": "hq",
  "empty": "hZ",
  "supplier": "hR",
  "supplier-content": "hQ",
  "supplier-avatar": "he mr-16",
  "installments": "hB body-2",
  "overflow-hidden": "hE",
  "ellipsis": "hd",
  "next-installment-date": "hI",
  "next-installment-amount": "ht body-2",
  "amount": "hM",
  "disclaimer": "hP",
  "disclaimer-icon": "hl",
  "total-repayment": "hX body-1"
};
