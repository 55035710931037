export default {
  "header": "ckk",
  "center": "ckJ",
  "header-placeholder": "cks",
  "placeholder": "ckF",
  "name-block": "ckg",
  "block": "ckD",
  "detail-block": "ckV",
  "border-top": "ckL"
};
