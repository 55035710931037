export default {
  "container": "cRt",
  "form-core": "cRM",
  "label": "cRP",
  "request-description": "cRl",
  "inline-inputs": "cRX",
  "placeholder-label": "cRC",
  "placeholder-input": "cRk",
  "placeholder-checkbox": "cRJ"
};
