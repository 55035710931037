export default {
  "filter": "ij",
  "expression": "iW",
  "conditional-wrapper": "iu mr-8",
  "conditional-text": "AS",
  "property-select": "Ac",
  "operator-select": "Aq",
  "error-message": "AZ",
  "values-field": "AR",
  "input-placeholder": "AQ",
  "filter-actions": "Ae",
  "label-color": "AB",
  "button-container": "AE"
};
