export default {
  "label-wrapper": "yY body-1",
  "avatar": "yh",
  "container": "yf",
  "transaction-info": "yK",
  "secondary": "yG",
  "amount-container": "yr",
  "amount": "yb",
  "disabled": "yy",
  "checkbox": "yU"
};
