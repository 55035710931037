export default {
  "container": "cQF",
  "has-background": "cQg",
  "wrapper": "cQD",
  "top-disclaimer": "cQV",
  "error-wrapper": "cQL",
  "bottom-btns": "cQT",
  "bottom-wrapper": "cQz",
  "tab-nav": "cQa",
  "tab-nav-small": "cQH",
  "error-illustration": "cQO",
  "error-title": "cQm title-1",
  "disclaimer-wrapper": "cQp",
  "disclaimer": "cQx",
  "error-description": "cQw body-2",
  "table-wrapper": "cQN",
  "title": "cQv title-1",
  "lineup-toggle": "cQo",
  "description": "cQn body-2",
  "loading-wrapper": "cQi",
  "cards-wrapper": "cQA",
  "card": "cQY",
  "plan": "cQh",
  "placeholder-header": "cQf",
  "disclaimer-info": "cQK",
  "spinner": "cQG"
};
