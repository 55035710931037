export default {
  "selector": "ra",
  "metal-design": "rH",
  "content": "rO",
  "header": "rm",
  "big": "rp",
  "header-name": "rx",
  "subtitle": "rw",
  "button": "rN",
  "features": "rv",
  "feature": "ro",
  "highlighted": "rn"
};
