export default {
  "journey": "cZk",
  "content": "cZJ",
  "distance": "cZs",
  "google-link-container": "cZF",
  "link-icon": "cZg",
  "continue-btn": "cZD",
  "origin": "cZV",
  "input-width": "cZL"
};
