export default {
  "container": "GC",
  "back-button": "Gk",
  "card-list": "GJ",
  "upsell-badge": "Gs",
  "pricing": "GF",
  "pricing-period": "Gg",
  "ad-modal-button": "GD",
  "eligibility-disclaimer": "GV",
  "feature": "GL",
  "highlighted": "GT"
};
