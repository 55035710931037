export default {
  "mt-16": "cgA",
  "title": "cgY",
  "edit-organization-form": "cgh",
  "beside-block": "cgf",
  "info-circle": "cgK",
  "hollow": "cgG",
  "filled": "cgr",
  "radio": "cgb",
  "cancel-button": "cgy",
  "error-message": "cgU"
};
