export default {
  "container": "cgj",
  "close-button": "cgW",
  "content-wrapper": "cgu",
  "main": "cDS",
  "description": "cDc",
  "calculation-item": "cDq",
  "total-km": "cDZ",
  "calculation-details": "cDR",
  "btn": "cDQ"
};
