export default {
  "mandate-new-no-mandate": "ua",
  "left-side": "uH",
  "left-side__wrapper": "uO",
  "left-side__wrapper-title": "um",
  "left-side__wrapper-details": "up",
  "left-side__wrapper-details-item": "ux",
  "right-side": "uw",
  "multi-accounts": "uN",
  "right-side__wrapper": "uv",
  "right-side__wrapper-name": "uo",
  "right-side__wrapper-address": "un",
  "right-side__wrapper-iban-dashed": "ui",
  "right-side__wrapper-address-label": "uA",
  "right-side__wrapper-iban-label": "uY",
  "right-side__wrapper-bic-label": "uh",
  "right-side__wrapper-address-dashed": "uf",
  "right-side__wrapper-bic-dashed": "uK",
  "dropdown-icon": "uG",
  "dropdown": "ur",
  "multi-accounts-illustration": "ub"
};
