export default {
  "container": "clf",
  "header": "clK",
  "content": "clG",
  "info-list": "clr",
  "info-label": "clb body-2",
  "info-value": "cly body-2",
  "provider": "clU",
  "policy-container": "clj",
  "policy-label": "clW body-2",
  "policy-number": "clu body-2",
  "copy-to-clipboard": "cXS",
  "button": "cXc btn btn--primary btn--stretch btn--large"
};
