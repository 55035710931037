export default {
  "item": "cFV",
  "selected": "cFL",
  "hover-revised": "cFT",
  "asset-container": "cFz",
  "asset": "cFa",
  "revoked-icon": "cFH",
  "pending-icon": "cFO",
  "revoked": "cFm",
  "revised-revoke": "cFp",
  "pending": "cFx",
  "revised-pending": "cFw",
  "invitable": "cFN",
  "info": "cFv",
  "name": "cFo",
  "email": "cFn",
  "actions": "cFi",
  "invitable-member-delete": "cFA",
  "invitable-member-invite": "cFY"
};
