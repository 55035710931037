export default {
  "mandate-list-item": "csI",
  "selected": "cst",
  "status-avatar": "csM",
  "infos": "csP",
  "info-title": "csl body-1",
  "info-title-suspended": "csX",
  "info-title-status-suspended": "csC",
  "info-mandate-count": "csk body-2"
};
