export default {
  "checks": "UR",
  "content": "UQ",
  "form": "Ue",
  "title": "UB",
  "description": "UE",
  "wrapper": "Ud",
  "aside": "UI",
  "amount-field": "Ut"
};
