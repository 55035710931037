export default {
  "step-container": "WC",
  "content": "Wk",
  "section": "WJ",
  "section-content": "Ws",
  "section-content-text": "WF",
  "section-illustration": "Wg",
  "close-form-cta": "WD",
  "bank-account-select": "WV"
};
