/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { ignoreCancelation, restartableTask } from 'ember-concurrency';
import window from 'ember-window-mock';

import { apiBaseURL, insuranceHubNamespace } from 'qonto/constants/hosts';
import { PROVIDERS_MAP } from 'qonto/constants/insurance-hub';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class InsuranceHubSidebarComponent extends Component {
  @service modals;
  @service networkManager;
  @service organizationManager;
  @service segment;
  @service sentry;

  @action
  onPreviewFile(attachment) {
    let {
      insuranceContract: { id, contractId, type, status },
    } = this.args;
    let country = this.organizationManager.organization.legalCountry;

    this.fetchAttachmentTask
      .perform(id, attachment.id)
      .then(response => {
        let { redirect_to: fileUrl } = response;

        this.modals.open('fullscreen/preview', {
          title: attachment.filename,
          isFullScreenModal: true,
          fileUrl,
          fileContentType: 'application/pdf',
          onDownload: () => {
            this.segment.track('insurance-hub_my-policies_policy_download-document_clicked', {
              policy_number: contractId,
              document_name: attachment.filename,
              product: type,
              policy_status: status,
              partner: PROVIDERS_MAP[country],
            });

            window.open(fileUrl, '_blank');
          },
        });
      })
      .catch(ignoreCancelation);

    this.segment.track('insurance-hub_my-policies_policy_document_clicked', {
      policy_number: contractId,
      document_name: attachment.filename,
      product: type,
      policy_status: status,
      partner: PROVIDERS_MAP[country],
    });
  }

  fetchAttachmentTask = restartableTask(async (id, attachmentId) => {
    try {
      return await this.networkManager.request(
        `${apiBaseURL}/${insuranceHubNamespace}/insurances/insurance_contracts/${id}/attachments/${attachmentId}`
      );
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}
