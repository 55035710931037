export default {
  "wrapper": "oN",
  "legend": "ov",
  "grid": "oo",
  "subtitle": "on body-2",
  "fiscal-code": "oi",
  "personal-data-name": "oA",
  "personal-data-birth": "oY",
  "personal-data": "oh",
  "tax-residence": "of",
  "payer-agent": "oK",
  "errors": "oG",
  "business": "or",
  "address": "ob"
};
