export default {
  "animated": "Gw",
  "container": "GN",
  "cards-selectors": "Gv",
  "card-selector": "Go",
  "selector": "Gn",
  "header": "Gi",
  "footer": "GA",
  "skip-cta": "GY",
  "pricing-caption": "Gh",
  "show": "Gf",
  "selectorFadeIn": "GK",
  "fadeIn": "GG",
  "asset-one": "Gr",
  "onePlusAssetFadeIn": "Gb",
  "assetMoveUp": "Gy",
  "asset-plus": "GU",
  "asset-x": "Gj",
  "xAssetFadein": "GW",
  "feature": "Gu",
  "highlighted": "rS"
};
