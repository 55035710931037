export default {
  "card": "rE",
  "asset": "rd",
  "container": "rI",
  "subtitle": "rt",
  "header": "rM",
  "header-name": "rP",
  "option-list": "rl",
  "feature": "rX",
  "highlighted": "rC"
};
