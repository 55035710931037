export default {
  "details": "cqy",
  "disclaimer": "cqU",
  "data": "cqj",
  "confirm": "cqW",
  "form": "cqu",
  "textarea": "cZS",
  "preview": "cZc",
  "form-title": "cZq title1",
  "form-subtitle": "cZZ title2",
  "divider": "cZR",
  "submit": "cZQ",
  "doc": "cZe",
  "amount-input-error": "cZB"
};
