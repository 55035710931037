export default {
  "wrapper": "cSa",
  "promotion-title": "cSH",
  "comparison-table": "cSO",
  "table-head": "cSm",
  "title-wrapper": "cSp",
  "illustration-wrapper": "cSx",
  "illustration": "cSw",
  "role-wrapper": "cSN",
  "role-disabled": "cSv",
  "role-name": "cSo",
  "role-access": "cSn",
  "disabled-caption": "cSi",
  "table-head-sticky": "cSA",
  "show-border": "cSY",
  "group-row": "cSh",
  "table-body": "cSf",
  "group": "cSK",
  "table-body-row": "cSG",
  "feature-description": "cSr body-2",
  "feature": "cSb body-2",
  "group-spacer": "cSy"
};
