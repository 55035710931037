export default {
  "details": "cgc",
  "details--ff": "cgq",
  "header-container": "cgZ",
  "icon": "cgR",
  "line-container": "cgQ",
  "info-line": "cge",
  "body-details": "cgB",
  "body-details-header": "cgE",
  "body-details-1": "cgd",
  "body-details-2": "cgI",
  "body-details-3": "cgt",
  "body-details-4": "cgM"
};
