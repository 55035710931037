export default {
  "container": "bn",
  "disabled-option": "bi",
  "content": "bA",
  "spinner": "bY",
  "subtitle": "bh",
  "card-panel": "bf",
  "card-icon": "bK",
  "card-description": "bG",
  "details-table": "br",
  "caption": "bb",
  "tooltip-icon": "by",
  "tooltip-icon-right": "bU",
  "card-monthly-cost": "bj",
  "card-monthly-discounted-cost": "bW",
  "strikethrough": "bu",
  "purple-text": "yS",
  "budget-label": "yc",
  "card-cost-label": "yq",
  "card-billing-account": "yZ"
};
