export default {
  "members-loading": "cgT",
  "members-loading--ff": "cgz",
  "header": "cga",
  "header-search-bar": "cgH",
  "body": "cgO",
  "members": "cgm",
  "placeholder-container": "cgp",
  "icon": "cgx",
  "details": "cgw",
  "member-loading": "cgN",
  "member-loading--no-ff": "cgv",
  "body-members": "cgo",
  "body-details": "cgn"
};
