export default {
  "cell": "Yw",
  "empty": "YN",
  "supplier": "Yv",
  "supplier-content": "Yo",
  "supplier-avatar": "Yn mr-16",
  "installments": "Yi body-2",
  "overflow-hidden": "YA",
  "ellipsis": "YY",
  "completed-on": "Yh",
  "financed-amount": "Yf body-2",
  "amount": "YK",
  "total-repayment": "YG body-1"
};
