export default {
  "row": "iJ",
  "row-active": "is",
  "cell": "iF body-2",
  "terminated": "ig",
  "amount": "iD body-1",
  "item-name-cell": "iV",
  "status-icon": "iL",
  "item-name-container": "iT",
  "item-info": "iz",
  "name": "ia body-1",
  "status-displayed": "iH caption"
};
