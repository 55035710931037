export default {
  "wrapper": "cRn",
  "wrapper-revamp": "cRi",
  "container": "cRA",
  "container-revamp": "cRY",
  "warning-label": "cRh",
  "subtitle-container": "cRf",
  "info-container": "cRK",
  "card": "cRG",
  "disclaimer": "cRr",
  "list": "cRb",
  "list-item": "cRy body-2"
};
