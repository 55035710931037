export default {
  "row": "hx",
  "cell": "hw",
  "cell-content": "hN",
  "supplier": "hv",
  "next-installment": "ho",
  "installment-amount": "hn",
  "total-repayment": "hi",
  "cell-details": "hA",
  "align-right": "hY",
  "empty": "hh"
};
