export default {
  "wrapper": "nX",
  "subtitle": "nC body-2",
  "legend": "nk",
  "table": "nJ",
  "footer": "ns",
  "footer-total-c": "nF",
  "footer-total-d": "ng",
  "footer-balance": "nD"
};
