export default {
  "wrapper": "ne",
  "legend": "nB",
  "operation-code": "nE",
  "table": "nd",
  "footer": "nI",
  "footer-detraction": "nt",
  "footer-total-g": "nM",
  "footer-total-h": "nP",
  "footer-net-g-h": "nl"
};
