export default {
  "header": "YP",
  "header-top": "Yl",
  "header-inner": "YX",
  "disclaimer": "YC mb-16",
  "header-main-wrapper": "Yk",
  "header-main": "YJ",
  "logo": "Ys mr-16",
  "header-right": "YF",
  "partner-description": "Yg body-2"
};
