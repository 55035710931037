export default {
  "wrapper": "oy",
  "subtitle": "oU body-2",
  "legend": "oj",
  "table": "oW",
  "footer": "ou",
  "footer-codice-1": "nS",
  "footer-codice-2": "nc",
  "footer-total-a": "nq",
  "footer-total-b": "nZ",
  "footer-balance": "nR",
  "errors": "nQ"
};
