export default {
  "header-cell": "iI caption-bold",
  "sortable": "it",
  "animated": "iM",
  "fadein-item": "iP",
  "animated-cell": "il",
  "col1": "iX",
  "header-content": "iC caption-bold",
  "active": "ik"
};
