export default {
  "step-container": "jG",
  "content": "jr",
  "detail": "jb",
  "secondary": "jy",
  "reference": "jU",
  "fees-label": "jj",
  "fees-tooltip": "jW",
  "separator": "ju",
  "mt-32": "WS"
};
