export default {
  "page-wrapper": "Ww",
  "container": "WN",
  "content": "Wv",
  "showElement": "Wo",
  "illustration": "Wn",
  "fadeIn": "Wi",
  "scaleDown": "WA",
  "error-content": "WY",
  "error-illustration": "Wh"
};
