/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LAYOUT, TRACKING_ORIGINS } from 'qonto/constants/empty-states/system';

export default class EmptyStateComponent extends Component {
  @service segment;

  constructor() {
    super(...arguments);

    let trackingData = this.args.emptyStateOptions?.tracking?.({
      isClickEvent: false,
    });
    if (trackingData?.name && trackingData.properties) {
      this.segment.track(trackingData.name, {
        ...trackingData.properties,
      });
    }
  }

  get isInformLayout() {
    return !this.args.emptyStateOptions || this.args.emptyStateOptions.layout === LAYOUT.INFORM;
  }

  get isDiscoverPlainLayout() {
    return this.args.emptyStateOptions.layout === LAYOUT.DISCOVER_PLAIN;
  }

  get shouldShowCTA() {
    return this.args.emptyStateOptions.ctaText && !this.isDiscoverPlainLayout;
  }

  get headerOrigin() {
    return TRACKING_ORIGINS.HEADER;
  }

  get primaryOrigin() {
    return TRACKING_ORIGINS.PRIMARY;
  }

  @action
  onHeaderCtaClick() {
    this.args.emptyStateOptions.onClick?.(TRACKING_ORIGINS.HEADER);
  }

  @action
  onDiscoverCtaClick() {
    this.args.emptyStateOptions.onClick?.(TRACKING_ORIGINS.PRIMARY);
  }
}
