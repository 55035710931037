export default {
  "fieldset": "cCm",
  "subscription-period": "cCp",
  "automatic-number-section": "cCx",
  "automatic-number-label": "cCw",
  "icon-tooltip": "cCN",
  "date-picker": "cCv",
  "label": "cCo",
  "header-text-field": "cCn",
  "header-text-field-container": "cCi",
  "header-text-field-add-button": "cCA btn btn--tertiary",
  "header-text-field-close-button": "cCY btn btn--icon-only btn--tertiary btn--small",
  "read-only-frequency": "cCh",
  "read-only": "cCf"
};
