export default {
  "body": "cFh",
  "members": "cFf",
  "members-results": "cFK",
  "members-list": "cFG",
  "members-title": "cFr caption-bold",
  "member": "cFb",
  "empty-illustration": "cFy",
  "empty-title": "cFU title-3",
  "empty-description": "cFj body-2",
  "invitable-members": "cFW",
  "details": "cFu",
  "pagination-footer": "cgS"
};
