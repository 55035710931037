export default {
  "checks": "Ug",
  "wrapper": "UD",
  "form": "UV",
  "title": "UL",
  "wrapper-body": "UT",
  "aside": "Uz",
  "steps-container": "Ua",
  "step": "UH",
  "container-dot": "UO",
  "text": "Um",
  "text-title": "Up",
  "text-desc": "Ux",
  "start-button": "Uw"
};
