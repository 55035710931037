export default {
  "container": "rY",
  "container-metal": "rh",
  "content": "rf",
  "button": "rK",
  "embossed-selector": "rG",
  "carousel-container": "rr",
  "carousel": "rb",
  "asset": "ry",
  "poster": "rU",
  "video-embossed": "rj",
  "video": "rW",
  "active": "ru",
  "embossed": "bS",
  "safari": "bc",
  "poster-open": "bq"
};
