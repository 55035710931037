export default {
  "upsell-cta": "cci",
  "discover-badge": "ccA",
  "role-selection-item": "ccY",
  "unavailable": "cch",
  "role-headline": "ccf",
  "illustration": "ccK",
  "role-description": "ccG",
  "description-item": "ccr",
  "description-icon": "ccb",
  "description-text": "ccy",
  "current-role": "ccU"
};
