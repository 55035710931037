export default {
  "wrapper": "nm",
  "legend": "np",
  "input": "nx",
  "table": "nw",
  "footer": "nN",
  "footer-total-m": "nv",
  "footer-total-n": "no",
  "footer-balance": "nn"
};
