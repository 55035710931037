export default {
  "form": "AD",
  "fieldset": "AV",
  "form-body": "AL",
  "conditional-select": "AT",
  "conditional-text": "Az",
  "group": "Aa",
  "add-filter-button": "AH btn btn--tertiary",
  "footer": "AO",
  "btn-icon": "Am",
  "add-icon": "Ap",
  "cancel-button": "Ax btn",
  "form-header": "Aw",
  "align-start": "AN",
  "align-right": "Av",
  "button-wrapper": "Ao",
  "wrapper": "An",
  "label": "Ai body-2",
  "input-wrapper": "AA",
  "input": "AY input-field body-2",
  "error": "Ah",
  "error-message": "Af"
};
