export default {
  "container": "cCy",
  "form-container": "cCU",
  "title": "cCj",
  "title-border": "cCW",
  "tabs-container": "cCu",
  "preview-container": "ckS",
  "email-preview-container": "ckc",
  "close-button": "ckq btn btn--icon-only btn--tertiary btn--large"
};
