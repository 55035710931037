export default {
  "header-cell": "ckK",
  "empty": "ckG",
  "header-content": "ckr caption-bold",
  "active": "ckb",
  "align-right": "cky",
  "first-col": "ckU",
  "mid-col": "ckj",
  "status-col": "ckW"
};
