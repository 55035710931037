export default {
  "wrapper": "cRU",
  "confirm": "cRj",
  "plan-info": "cRW",
  "plan-info-left": "cRu",
  "info-title": "cQS body-1",
  "initial-trial-content": "cQc",
  "extra-fees-item": "cQq body-2",
  "extra-fees-container": "cQZ",
  "bottom-wrapper": "cQR",
  "total-price-container": "cQQ",
  "extra-fees-element": "cQe body-1",
  "border": "cQB",
  "subtitle": "cQE body-2",
  "group-item": "cQd",
  "amount": "cQI",
  "subscription-billing-summary": "cQt",
  "summary": "cQM",
  "summary-list": "cQP",
  "disclaimer": "cQl",
  "disclaimer-revamp": "cQX",
  "error": "cQC",
  "error-link": "cQk",
  "warning-wrapper": "cQJ",
  "warning-label": "cQs body-1"
};
